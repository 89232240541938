<template>
    <div class="block-why">
        <h3 class="mb-5">Бережная вакцинация в "Клинике Фомина. Дети"</h3>
        <div class="block-why-list md:flex mb-5">
            <div class="block-why-card flex md:flex-1 mb-3" v-for="item in listItem">
                <div class="block-why-card--img flex-none me-2">
                    <img :src="item.img" class="max-w-full h-auto">
                </div>
                <div class="flex flex-col justify-center">
                    <div class="block-why-card--header">
                        {{ item.h }}
                    </div>
                    <div class="block-why-card--label">
                        {{ item.label }}
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <NuxtLink
                to="/vacc/advantage"
                    class="inline-flex cursor-pointer items-center justify-center px-6 py-2 border border-pink-600 rounded-full text-sm xl:text-base font-medium text-pink-600 bg-white hover:text-black transition-colors">
                Подробнее
            </NuxtLink>
        </div>
    </div>


</template>
<script>
export default {
    setup() {
        return {
            listItem: [
                {
                    img: '/img/vacc/magic.webp',
                    h: 'Не страшно',
                    label: 'Отвлекаем, развлекаем, используем специальные аппликаторы и дарим подарки за храбрость.'
                },
                {
                    img: '/img/vacc/snow.webp',
                    h: 'Безопасно',
                    label: 'Закупаем вакцины только у надежных постащиков, контроль за доставкой и хранением, регулярно обучаем медсестер.'
                },
                {
                    img: '/img/vacc/vr.webp',
                    h: 'Удобно',
                    label: 'Все возможные вакцины в наличие, совмещаем вакцинацию с плановыми приемами, врач остается на связи после.'
                },
            ]
        }
    }
}
</script>
<style lang="scss">
.block-why {
  h3 {
    color: #343434;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
    @media screen and (min-width: 768px) {
      font-size: 48px;
      font-style: normal;
      font-weight: 350;
      line-height: 48px;
    }

  }

  &-card {
    &--img {
      width: 72px;
      height: 72px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &--header {
      color: #343434;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }

    &--label {
      opacity: 0.5;
      color: #343434;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }
}
</style>