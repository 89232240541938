<template>
    <div class="card-indivudals">
        <NuxtLink to="/vacc/individual">
            <div class="bg-white flex justify-between flex-col px-5 py-5 border-white text-gray-900 hover:bg-gray-50 border rounded-[24px] mb-3 cursor-pointer">
                <div class="flex flex-col md:flex-row-reverse">
                    <div class="md:w-1/2 mb-5"><img :src="img"/></div>
                    <div class="md:w-1/2 md:flex md:flex-col md:justify-evenly md:ps-12">
                        <div class="md:max-w-[85%]">
                            <div class="card-indivudal--header md:mb-5 mb-1">
                                {{ h }}
                            </div>
                            <div class="card-indivudal--label">
                                {{ label }}
                            </div>
                        </div>
                        <div class="text-right md:text-left">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none"
                                 class="inline-block">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M14.0138 4.8C14.0138 8.36854 16.8794 11.25 20.4001 11.25V12.75C16.8794 12.75 14.0138 15.6315 14.0138 19.2H12.5138C12.5138 16.5477 13.8037 14.1949 15.7885 12.75L3.59998 12.75L3.59998 11.25L15.7885 11.25C13.8037 9.80508 12.5138 7.45236 12.5138 4.8H14.0138Z"
                                      fill="#7EC1BE"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </NuxtLink>
    </div>
</template>
<script>
export default {
    props: {
        img: String,
        h: String,
        label: String,
    },
    setup(props) {
        return {
            img: props?.img?.length ? props?.img : '/img/vacc/individual_schedule.webp',
            h: props?.h?.length ? props?.h : 'Индивидуальный график вакцинации',
            label: props?.label?.length ? props?.label : 'Наши педиатры составят индивидуальный график вакцинации с учетом сделанных вакцин, рекомендациями ВОЗ, возраста и места проживания ребенка.',
        }
    }
}
</script>

<style lang="scss">
.card-indivudal {
  &--header {
    color: #343434;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    @media screen and (min-width: 768px) {
      color: #343434;
      font-size: 48px;
      font-style: normal;
      font-weight: 350;
      line-height: 48px;
    }
  }

  &--label {
    color: #343434;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    opacity: 0.5;

    @media screen and (min-width: 768px) {
      color: #343434;
      font-size: 20px;
      font-style: normal;
      font-weight: 350;
      line-height: 28px;
    }
  }
}
</style>